import { Controller } from "@hotwired/stimulus"

export default class extends Controller {    
  static values = {
    confirmMessage: { type: String, default: "Are you sure you want to send an invitation to ${email}?" }
  }
  
  updateConfirmMessage(updateEvent) {
    const updatedEmailValue = updateEvent.target.value
    const newConfirmMessage = this.getConfirmMessageWithEmail(updatedEmailValue)
    this.element.dataset.turboConfirm = newConfirmMessage
  }
  
  getConfirmMessageWithEmail(email) {
    return this.confirmMessageValue.replace("${email}", email)
  }
}